html {
  height: 100%;
}

body {
  --color-text-contrast: #f5f5f5;
  --color-label: #b5b5b5;
  --color-meh: #808080;

  --shadow-light: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);

  background: #fafafa;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
}

@media (prefers-color-scheme: dark) {
  body {
    background: var(--color-gray-6);
  }
}

h1, h2, h3, h4, h5, h6 {
  &:first-child {
    margin-top: 0;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

:global(#root) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

:global(#root #pp-cfpw h1) {
  color: var(--color-text);
}

:global(#root #pp-cfpw h2) {
  color: var(--color-text);
}

:global(#root #pp-cfpw li) {
  color: var(--color-text);
}

:global(#root #pp-cfpw p:not(.pp-cfpw__form-validation)) {
  color: var(--color-text);
}

:global(#root #pp-cfpw .pp-cfpw-dialog) {
  background: var(--color-gray-3);
}

:global(#root #pp-cfpw input) {
  background: var(--input-text-background-color);
  border: var(--input-border);
  color: var(--color-text);
}

:global(#root #pp-cfpw textarea) {
  background: var(--input-text-background-color);
  border: var(--input-border);
  color: var(--color-text);
}

:global(.swal-modal) {
  background-color: var(--card-background);
}

:global(.swal-title) {
  color: var(--color-text);
}

@media (prefers-color-scheme: dark) {
  :global(#root #pp-cfpw .pp-cfpw__ideas) {
    background: var(--color-gray-5);
    border-top: 1px solid var(--color-gray-4);
  }

  :global(#root #pp-cfpw .pp-cfpw__list li) {
    background: var(--color-gray-4);
    border: 1px solid var(--color-gray-3);
  }

  :global(#root #pp-cfpw .pp-cfpw__list-item .pp-cfpw__list-item-description:before) {
    background: linear-gradient(rgba(255,255,255,0) 110px, var(--color-gray-4));
  }
}
